/* Стили для кнопки открытия модального окна */
.open-modal-button {
     position: fixed;
    background: transparent;
   background: linear-gradient(to bottom right, #ffffff, #00aaff);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    z-index: 999;
}

.open-modal-button:hover {
  background-color: #095a9d;
  transform: scale(1.05);
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
 
height: 25px;
  cursor: pointer;
}
.nav__info{
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-right: 15px;
}
.tag-cloud span {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.4s, background-color 0.4s;
}

.tag-cloud span:hover {
  transform: scale(1.1);
  background-color: #34a853;
}

.tag-cloud span:active {
  transform: scale(0.9);
  background-color: #fbbc05;
}
.about {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;
}
.about .bg_links {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
}
.about  {
  width: 40px;
  height: 40px;
  z-index: 9;
  background-image: url(https://rafaelavlucas.github.io/assets/codepen/logo_white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10px 7px;
  opacity: 0.9;
  transition: all 1s 0.2s ease;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.about .social {
  opacity: 0;
  right: 0;
  bottom: 0;
}
.about .social .icon {
  width: 100%;
  height: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: flex;
  transition: all 0.2s ease, background-color 0.4s ease;
  opacity: 0;
  border-radius: 100%;
}
.about .social.portfolio {
  transition: all 0.8s ease;
}
.about .social.portfolio .icon {
  background-image: url(https://rafaelavlucas.github.io/assets/codepen/link.svg);
}
.about .social.dribbble {
  transition: all 0.3s ease;
}
.about .social.dribbble .icon {
  background-image: url(https://rafaelavlucas.github.io/assets/codepen/dribbble.svg);
}
.about .social.linkedin {
  transition: all 0.8s ease;
}
.about .social.linkedin .icon {
  background-image: url(https://rafaelavlucas.github.io/assets/codepen/linkedin.svg);
}
.about:hover {
  width: 105px;
  height: 105px;
  transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .logo {
  opacity: 1;
  transition: all 0.6s ease;
}
.about:hover .social {
  opacity: 1;
}
.about:hover .social .icon {
  opacity: 0.9;
}
.about:hover .social:hover {
  background-size: 28px;
}
.about:hover .social:hover .icon {
  background-size: 65%;
  opacity: 1;
}
.about:hover .social.portfolio {
  right: 0;
  bottom: calc(100% - 40px);
  transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.portfolio .icon:hover {
  background-color: #698fb7;
}
.about:hover .social.dribbble {
  bottom: 45%;
  right: 45%;
  transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.dribbble .icon:hover {
  background-color: #ea4c89;
}
.about:hover .social.linkedin {
  bottom: 0;
  right: calc(100% - 40px);
  transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.linkedin .icon:hover {
  background-color: #0077b5;
}

* {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}

body {
  font-family: "Raleway", sans-serif;
}

.mainNav {
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9197ae;
  text-transform: uppercase;
  padding: 0 40px;
}
@media screen and (max-width: 799px) {
  .mainNav {
    padding: 0 20px;
  }
}
.mainNav__logo {
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 18px;
}
.mainNav__links {
  display: flex;
}
@media screen and (max-width: 799px) {
  .mainNav__links {
    display: none;
  }
}
.mainNav__link {
  letter-spacing: 1px;
  font-size: 14px;
  margin-left: 20px;
  font-weight: 600;
  box-shadow: inset 0px -10px 0px rgba(255, 255, 255, 0.5);
  transition: all 0.4s ease, transform 0.2s ease;
  padding: 2px 4px;
  transform: translateY(0px);
}
.mainNav__link:hover {
  transform: translateY(-5px);
  box-shadow: inset 0px -20px 0px white;
}
.mainNav__icon {
  background-image: url(https://rafaelalucas91.github.io/assets/icons/black/icon-141.svg);
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}
@media screen and (max-width: 799px) {
  .mainNav__icon {
    display: block;
  }
}

.mainHeading {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 40px;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd00 100%);
}
@media screen and (max-width: 799px) {
  .mainHeading {
    padding: 0 20px;
  }
}
.mainHeading__content {
  max-width: 1110px;
  width: 90%;
margin:0 15px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

}
@media screen and (max-width: 799px) {
  .mainHeading__content {

    top: 0px;
  }
}
.mainHeading__text {
  z-index: 1;
  color: #ffffff;
  background: #445669cf;
  padding: 40px;
  max-width: 620px;

  width: 50%;
  backdrop-filter: blur(3px);
  animation: text 0.8s 0.6s ease backwards;
  position: relative;
  text-align: center;
  border-radius: 5px;
}
.mainHeading__text:before {
  content: "";
  position: absolute;

  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  animation: line 0.8s 0.6s ease backwards;
}
@keyframes line {
  0% {
    right: 0;
    width: 100%;
    opacity: 0;
  }
}
@media screen and (max-width: 799px) {
  .mainHeading__text {
    padding: 20px;
    margin: 0px 0 40px 0;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;
  }
}
@keyframes text {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
}
.mainHeading__preTitle {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  color: #ffffff;
}
.mainHeading__title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;

  font-size: 40px;
  color: #ffffff;
}
@media screen and (max-width: 799px) {
  .mainHeading__title {

    font-size: 28px;
  }
}
.mainHeading__description {
 letter-spacing: 0.5px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #ffffff;
}
@media screen and (max-width: 799px) {
  .mainHeading__description {
    font-size: 14px;
  }
}
.mainHeading__image {
top: -260px;
    right: 0;
    max-width: 900px;
    width: 70%;
  height: 520px;
  transform: translatey(100px);
  position: absolute;
  overflow: hidden;
  animation: image 0.6s 0.2s ease backwards;
  border-radius: 15px;
}
@media screen and (max-width: 799px) {
  .mainHeading__image {
    /* height: 330px; */
    width: 75%;
    transform: translatey(80px);
    right: 5px;
    top: -144px;

  }
}
@keyframes image {
  0% {
    opacity: 0;
    transform: translatey(200px);
  }
}
.mainHeading__image:before, .mainHeading__image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0%;
  top: 100%;
  background-image: linear-gradient(to top, #1191d1 0%, #e7f0fd00 100%);
  opacity: 1;
  left: 0;
}
.mainHeading__image:before {
  animation: imageBefore 1s 0.2s ease backwards;
}
@keyframes imageBefore {
  0% {
    height: 100%;
    top: 0;
  }
}
.mainHeading__image:after {
  background-image: linear-gradient(to top, #accbee00 0%, #e7f0fd00 100%);
  height: 100%;
  top: 0;
  opacity: 0.2;
}
.mainHeading__image img {
  width: 100%;
  height: 100%;
}

.cta {
  padding: 16px 32px;
  color: #637498;
  background-color: transparent;
  border: 1px solid rgba(99, 116, 152, 0.4);
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 32px;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px rgba(99, 116, 152, 0.2);
  transition: all 0.4s ease;
}
.cta:hover {
  border: 1px solid rgba(99, 116, 152, 0.1);
  box-shadow: inset 0px -80px 0px rgba(99, 116, 152, 0.1);
  transform: translateY(-5px);
}
@media screen and (max-width: 799px) {
  .cta {
    margin-top: 16px;
  }
}


@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.spiral__anim{
display: flex;
align-items: center;
gap: 0;
position: absolute;
color: #000000;
font-family: "Bebas Neue";
font-size: 60rem;
height: 80%;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
font-size: 62.5%
}

@keyframes spiral{
0%{
  --angle: 0deg;
}
100%{
  --angle: 360deg;
}
}

.character{
  font-size: 2.8rem;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  transform: translateY(calc(sin(var(--angle)) * 100px)) scale(calc(cos(var(--angle)) * 0.5 + 0.5));
  animation: spiral 4s linear infinite;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation-delay: calc(var(--index) * -0.1s);



}

@media (max-width: 490px){
.character{
  font-size: 2.2rem
}
}
.spiral{
height: 100%;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
color: #040509;
overflow: hidden;
font-size: 62.5%

}


.container__contacts{
    position: sticky;
    height: 80x;
    display: flex;
  background: linear-gradient(to bottom right, #003366, #006699);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
    width: 100%;
    padding: 1% 5%;
    gap: 24px;
    color: #b3b3b3;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}

.content-holder{
color: white;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    text-decoration: none;
    width: 20%;
   
}



.contacts__icon {
  list-style: none;
  display: flex;
}

.contacts__icon-sochial {
  margin-right: 10px;
}

.contacts__icon-sochial a {
  color: #ffffff; /* устанавливаем начальный белый цвет */
  text-decoration: none;
  display: inline-block;
}

.contacts__icon-sochial a:hover {
  color: #f9a92c; /* меняем цвет при наведении на красный */
}

.contacts__icon-sochial a span {
  display: none;
  position: absolute;
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.contacts__icon-sochial a:hover span {
  display: block;
}


@keyframes fade{
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.logo{
  filter: drop-shadow(1px 1px 2px #ffffff);
      background: white;
    filter: drop-shadow(1px 1px 2px #ffffff);
    width: 50px;
    height: 38px;
}

.header{
  position: relative;
    top: 0;
    left: 0;
    z-index: 3;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}
.header__top {
height: 70px;

   white-space: nowrap;
  align-items: center;
  justify-content: space-between;
padding: 5px 10px;
    margin: 0 auto;
    border-top: 2px solid #999393;
    width: calc(100% - 1*40px);
    z-index: 3;
    max-height: 70px;
    overflow-x: auto;
    box-sizing: border-box;
background: linear-gradient(to bottom right, #ffffff, #00aaff);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
        box-shadow: 2px 8px 25px 3px grey;

  

}
.header__links ul, .contact-info, .button {
    display: inline-block; 
    vertical-align: top; 
    margin-right: 20px; 

}
.contact-info p {
    margin: 0; 
}
.form__logo{
  max-width: 100px;
  cursor: pointer;
}

.nav__btn{
display: none;
}

.navigation {
  margin: 0 auto;
  display: flex;
  gap: 24px;
  padding: 0 15px;
}
.nav__list-foto{
  list-style: none;
    text-decoration: none;
    cursor: pointer;
    color: black;
 }
.navigation__lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  color: #ffffff;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  text-decoration: none;
  cursor: pointer;

  background-size: 200% 100%;
  transition: background-position 0.5s;
}
.burger-menu__list{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contact-info__map-link {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin: 0 auto;
  cursor: pointer;
  max-width: 150px;
}
.contact-info__map-link:hover {
  color: #F9A92C;
}

.contact-info__map-link:hover {
  opacity: .8;
}

.navigation__list a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 15px;
  font-family: Montserrat;
  line-height: 1;
}

.navigation__lists {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  color: #ffffff;
  text-decoration: none;
  gap: 24px;
  align-items: center;
  list-style: none;
}
.navigation__list a:hover {
  background-color: #F9A92C;
  color: rgb(0, 0, 0);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 20px;
}

.contact-info__location {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.contact-info__number {
  color: #000000;

font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  padding: 0;
  margin:  auto;
  max-width: 125px;
  cursor: pointer;
}

.location {
  width: 40px;
  height: 50px;
}
.header__middle{
  display: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;    

}
.header__middle-link{
  position: relative;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  text-decoration: none;
  cursor: pointer;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  margin: 0 auto;
}

.header__middle-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header__middle-link:hover::after {
  transform: scaleX(1);
}
.header__middle_links{
display: flex;


}
.header__links{
  display: flex;

}
.middle__links{
  background-color: rgb(0, 0, 0);
    padding: 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-decoration: none;

}
.middle__links:hover{
  background-color: #F9A92C;
}
.middle__links:active{
  background-color: gray;
}
.button {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
}
.button::after{
  inset: 100%;
  background: conic-gradient(from calc(270deg-(var(--glow-spead)*0.5)),
  var(glow)0,
  var(--glow) var(--glow-spead),
  transparent var(--glow-spead));
  translate: 0 0;
  transform: rotate(0deg);
  width: auto;
  animation: spin calc(var(--speed)*2s)infinite linear;
  animation-play-state: paused;
  }

.button img {
  display: block;
  max-width: 150px;

}

.button span {
position: absolute;
    top: 50%;
    left: 42%;

    font-family: Montserrat;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
   background: linear-gradient(to bottom right, #f9a92c, #0699f4);
    padding: 12px;
    border-radius: 10px;
}
.button-text {
  white-space: nowrap;
}
/* Медиазапросы */
@media (min-width: 501px) and (max-width: 768px) {
.burger-icon{
  width: 50px;
  height: 50px;
    position: relative;

        top: 0px;
        right: 0px;
    max-width: 90px;
    cursor: pointer;
    z-index: 999;
}
  .header__top{
max-width: 600px;
    padding-top: 10px;

  }
}

@media (min-width: 320px) and (max-width: 501px) {
  .header__top{
max-width: 320px;
    padding-top: 10px;

  }
  .nav-login-cart button {
        margin-right: 20px;
  }
  .nav-menu{
    font-size: 12px;
    gap: 8px;
  }
  .navigation__lists{
    font-size: 12px;
    gap: 10px;
  }
  .contacts__icon {
    list-style: none;
    display: flex
;
    flex-direction: row;
    padding: 0;
    margin: 15px auto;
    gap: 5px;
}
  .navigation{
    gap: 10px;
  }
  .navigation__list a{
    font-size: 12px;
  }
  .header__middle{
    display: none;
  }
  .logo {
        width: 30px;
    height: 30px;
  }
  .contacts__block{
    padding: 0;
  }

  .content-holder{
  display: none;
  }
.nav-login-cart{
        height: 70px;
        display: flex;
        outline: none;
        border: 1px solid #000000;
        border-radius: 15px;
        color: white;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
      
        cursor: pointer;
         font-family: Bebas Neue;
        margin: 0 auto;
    
        align-items: center;
        padding: 10px 20px;
    
}
.nav-cart-count{
    width: 11px;
    height: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -26px;
    margin-left: -29px;
    border-radius: 11px;
    font-size: 8px;
    background: red;
    color: white;
}
.contacts__icon-sochial{
  margin: 0;
}
  

.contact-info{
display: none;
}
.nav-login-cart{
  gap: 0px;
}


  
  .nav__btn{
    display: none;
  }
  .button {
 display: none;
}
  .burger {
    display: inline-block;
    width: 30px;
    height: 20px;
    background-color: #000;
    position: relative;
    cursor: pointer;
  }
  
  .burger::before,
  .burger::after {
    content: "";
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 2px;
  }
  
  .burger::before {
    top: -6px;
  }
  
  .burger::after {
    bottom: -6px;
  }
.contact-info__number{
  font-size: 12px;
  font-weight: 600;
  line-height: normal;

}
.burger-icon{
width: 40px;
    height: 40px;
    height: 40px;
    position: absolute;
    top: -10px;
    top: -16px;
    right: -23px;

    cursor: pointer;
    z-index: 999;
 

}
.contact-info__map-link{
  font-size: 8px;
}
  .button img{
    width: 100px;
    display: none !important;;
  }
  .location {
    width: 15px;
    height: 15px;
}
.contact-info__location{
  font-size: 8px;
}
  .button span{
    font-size: 7px;
    left: 41px;
    font-weight: bold;
  }
  .contact-info__map-link:hover {
    color: #F9A92C;
  }
  .mainHeading__preTitle{
    font-size: 11px;
    letter-spacing: 0px;
  }
  .mainHeading__title{
    font-size: 13px;
  }
  .mainHeading__description{
    letter-spacing: 0.5px;
    line-height: 14px;
    font-size: 12px;
  }
  .mainHeading__content{
 
    top: 0px;

  }
.mainHeading__image{

    width: 75%;
    transform: translatey(80px);
    right: 5px;
    top: -182px
}
.container__contacts{
  gap: 0px;
}
}

@media (min-width: 501px) and (max-width: 768px) {

  .form__logo {
    max-width: 100px;
  
}
.header__middle{
  display: none;
}
  .button {
    max-width: 200px;
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
    border-radius: 10px;
}
.nav__btn{
  display: block;
}
.button img {
  display: block;
  max-width: 200px;
}

  .header__top{
   
    padding-top: 10px;
    max-width: 500px;
  }
  .header__middle{
    display: none;
  }


.location{
  width: 20px;
  height: 20px;
}
  .contact-info__number{
    font-family: Gilroy;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.2px;

  }
  .contact-info__map-link{
    font-size: 9px;
  }


  .button span{
    font-size: 5px;
    font-weight: bold;
  }
  .button img{
    max-width: 80px;
  }
  .content-holder{
   display: none;
  }
  .mainHeading__preTitle{
    font-size: 13px;
  }
  .container__contacts{

        gap: 0px;
  }

.mainHeading__title{
  font-size: 18px;
  font-weight: bold;
}
.mainHeading__description{
  font-size: 14px;
  line-height: 18px;
}
.mainHeading__image{
  width: 67%;
  top: -147px;
}
.contact-info__location{
  font-size: 9px;
}
.contact-info{
  display: none;
}
}

@media (min-width: 769px) and (max-width: 1024px) {

  .logo {
    width: 65px;
    height: 45px;
    background: white;
    border-radius: 5px;
  }
}

@media (min-width: 1025px) {
  .logo {
    width: 65px;
    height: 45px;
        background: white;
    border-radius: 5px;
  }
}
.nav_middle{
  display: none;
}

.nav__list{
  list-style: none;
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: bold;
  background-image: linear-gradient(to right, #ffffff 50%, #f9a92c 50%);
  background-size: 200% 100%;
  transition: background-position 0.5s;
  }

  .nav__list:hover{
    background-color: #F9A92C;
    background-position: -100%;
  }
  .card__subtitle{
    list-style: none;
    text-decoration: none;
  }
  .card__subtitle-img{
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
    margin: 0;
  }

  
@media (min-width: 769px) and (max-width: 990px) {
  .header__top{
   width: calc(100% - 1*80px);
   max-width: 700px;
   
  }
  .nav_middle{
  display: block;
  margin: 0 auto;

  }
}
.nav__lists{
list-style: none;
display: flex;
align-items: center;
gap: 10px;

font-size: 13px;
font-family: Montserrat;
font-weight: bold;
}
