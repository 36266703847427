.news-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  position: relative;
  z-index: 9999;
}
/* NewsStyles.css */
.loading {
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
}
.news-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: 15px;
}

.news-thumbnail {
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.news-thumbnail:hover {
  transform: scale(1.03);
}
.error {
  color: #dc3545;
  padding: 20px;
  border: 1px solid #dc3545;
  margin: 20px;
  border-radius: 4px;
}

.news-list {
  list-style: none;
  padding: 0;
  position: relative;
}

.news-item {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.news-image {
  max-width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 4px;
}

.news-video {
  width: 100%;
  margin-top: 15px;
}

.news-actions {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.btn-edit {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.btn-delete {
  padding: 8px 15px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
}

.news-form {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  margin-bottom: 3rem;
}

.news-form h2 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 600;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
}

.form-group input[type="file"] {
  padding: 0.5rem;
  border: 2px dashed #cbd5e0;
  border-radius: 8px;
  width: 100%;
}

.news-list {
  display: grid;
  gap: 2rem;
}

.news-item {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.08);
  transition: transform 0.3s ease;
}

.news-item:hover {
  transform: translateY(-5px);
}

.news-item img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin: 1rem 0;
}

.news-item video {
  width: 100%;
  border-radius: 8px;
  margin: 1rem 0;
}

.actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.button-primary {
  background: #4299e1;
  color: white;
}

.button-primary:hover {
  background: #3182ce;
}

.button-danger {
  background: #f56565;
  color: white;
}

.button-danger:hover {
  background: #e53e3e;
}

.alert {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.alert-success {
  background: #c6f6d5;
  color: #22543d;
}

.alert-error {
  background: #fed7d7;
  color: #742a2a;
}
/* NewsStyles.css */
.news-content {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.news-content-text {
  margin: 15px 0;
  line-height: 1.6;
  color: #333;
}

.news-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 10px 0;
}

.btn-delete {
  background: #dc3545;
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.btn-delete:hover {
  background: #c82333;
}

/* Public News Styles */
.news-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.news-detail-content{
  position: relative;
}
.news-detail-image{
  position: relative;
}
.news-detail-page{
  position: relative;
}
.page-title {
  text-align: center;
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.news-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.news-image-wrapper {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.news-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-card:hover .news-card-image {
  transform: scale(1.05);
}

.news-card-content {
  padding: 1.5rem;
}

.news-card-title {
  font-size: 1.4rem;
  margin: 0 0 1rem;
  color: #2c3e50;
  line-height: 1.3;
}

.news-card-excerpt {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.news-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.news-card-date {
  font-size: 0.9rem;
  color: #7f8c8d;
}

.read-more {
  color: #3498db;
  font-weight: 500;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .news-grid {
    grid-template-columns: 1fr;
  }
  
  .page-title {
    font-size: 2rem;
  }
  
  .news-card-title {
    font-size: 1.2rem;
  }
}