.footer{
  padding: 30px;
padding-top: 20px;
position: relative;
z-index: 2;
background: linear-gradient(to bottom right, #003366, #006699);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
}
.footer__map {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
  justify-items: center;
}

.taxi-widget-container {
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.taxi-load-button {
  padding: 24px 20px;
    background: #007bff;
    color: white;
    text-align: center;
    border: none;
    display: flex
;
    width: 300px;
    border-radius: 5px;
    cursor: pointer;
    margin: 15px 0;
    height: 27%;
    align-items: center;
    justify-content: center;
}

.geo-error {
  color: #dc3545;
  padding: 10px;
  background: #f8d7da;
  border-radius: 5px;
  margin: 10px 0;
}

.taxi-widget-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin: 15px 0;
}

.ya-taxi-widget {
  min-height: 70px;
}

.footer__contacts {
  text-align: center;
  grid-column: 1 / -1;
}

.go-img {
  max-width: 200px;
  transition: transform 0.3s ease;
}

.go-img:hover {
  transform: scale(1.05);
}

.footer__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
}

.company-info {
  flex: 1 1 300px;
}

.info-title {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer__text {
  color: #7f8c8d;
  line-height: 1.6;
  font-size: 0.9rem;
}

.privacy-link {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-block;
  margin-top: 1rem;
}

.privacy-link:hover {
  color: #2980b9;
}

.payment-section {
  flex: 1 1 180px;
}

.payment-title {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.payment-logo {
  height: 40px;
  transition: transform 0.3s ease;
  filter: grayscale(100%);
  opacity: 0.8;
}

.payment-logo:hover {
  filter: none;
  opacity: 1;
  transform: translateY(-2px);
}

.footer__bottom {
  border-top: 1px solid #ecf0f1;
  padding-top: 1rem;
  text-align: center;
}

.footer__author {
  color: #95a5a6;
  font-size: 0.9rem;
  margin: 0;
}

@media (max-width: 768px) {
  .footer__map {
    grid-template-columns: 1fr;
  }
  
  .taxi-widget-container {
    order: 2;
  }
  
  .footer__content {
    flex-direction: column;
  }
  
  .payment-methods {
    justify-content: center;
  }
  
  .go-img {
    max-width: 160px;
  }
}

@media (max-width: 480px) {
  .footer__map {
    gap: 1rem;
  }
  
  .payment-logo {
    height: 30px;
  }
  
  .footer__text {
    font-size: 0.8rem;
  }
}