.navbar {
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
    position: relative;
    z-index: 3;
    padding-top: 130px;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-logo p {
    color: #171717;
    font-size: 38px;
    font-weight: 600;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 24px;
    color: #000000;
       font-size: 15px;
    font-weight: 700;
       font-family: Montserrat;
    line-height: 1;
    padding-right: 40px;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;

 
    cursor: pointer;
}

.nav-menu{
    margin: 0;
    border: none;
    width: 40%;
   
    border-radius: 10px;
    
}

.nav-login-cart {
    display: flex;
    align-items: center;

}


.nav-login-cart button{
gap: 25px;
    display: flex;
    position: relative;
   margin-right: 30px;
  top: 0;
    /* gap: 10px; */
    outline: none;
    border: 1px solid #000000;
    border-radius: 15px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(to bottom right, #f9a92c, #02578b);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
    cursor: pointer;
    font-family: Bebas Neue;
    padding: 10px 15px;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}


.nav-login-cart button:active {
    background: #f3f3f3;
}

.nav-cart-count {
width: 11px;
        height: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -26px;
        margin-left: -29px;
        border-radius: 11px;
        font-size: 8px;
        background: red;
        color: white;
}