.about{
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}
.about__content{

    text-align: center;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    background: transparent;
    backdrop-filter: blur(1px);
}
.about__title{
color: #13171D;
font-family: Bebas Neue;
font-size: 70px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0 auto;
padding-top: 60px;
}
.about__text{
color: #13171D;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0 auto;

}
.about__subtitle{
color: #444;
font-family: Gilroy;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 133.333% */
text-align: center;
margin: 10px 10px;
}
.about__image{

    width: 80px;
    height: 80px;
}
.about__tech{
display: flex;
justify-content: center;
gap: 15px;
padding-bottom: 100px;
padding-top: 50px;
}
.about__tech_content{
    padding: 20px 20px 20px;
    max-width: 364px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgb(149, 149, 149);
    border-radius: 5px;

}

@media screen and (min-width: 400px) and (max-width: 768px){
    .about__title{
font-family: Bebas Neue;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0 auto;
    }
    .about__content{
        max-width: 350px;
    }
    .about__tech{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .about__tech_content{
        max-width: 275px;
    }
}

@media screen and (min-width: 320px) and (max-width: 499px){
    .about__image{
        width: 40px;
        height: 40px;
    }
    .about__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
    }
    .about__title{
        color: #13171D;
text-align: center;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .about__text{
        text-align: center;
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; 
        width: 290px;
    }

    .about__subtitle{
        color: #444;
        text-align: center;
font-family: Gilroy;
font-style: normal;
font-weight: 400;
line-height: 16px;

text-align: center;
font-family: Gilroy;
font-size: 12px;
    }
    .about__tech_content{
        max-width: 266px;
    }
    .about__tech{
        flex-direction: column;
        padding: 0;
    }
}
@media screen and (min-width: 769px) and (max-width: 900px){
    .about__image{
        width: 50px;
        height: 50px;
    }
.about__content{
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
}
.about__tech{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.completed__title{
color: #13171D;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 26px;
max-width: 268px;
padding-bottom: 20px;
text-align: center;
}
}
@media (min-width: 990px) {

}
