.burger-menu {
  position: relative;
  z-index: 1000;
}

.burger-toggle {
  position: relative !important;

  top: 0px;
left: 0px;
     background: linear-gradient(to bottom right, #f9a92c, #02578b);
  border: none;
  padding: 15px;
  border-radius: 30%;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.burger-line {
  width: 20px;
  height: 2px;
  background: white;
  transition: all 0.3s ease;
  transform-origin: center;
}

.burger-toggle.open .top {
  transform: translateY(7px) rotate(45deg);
}

.burger-toggle.open .middle {
  opacity: 0;
}

.burger-toggle.open .bottom {
  transform: translateY(-7px) rotate(-45deg);
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.burger-menu.open .menu-overlay {
  opacity: 1;
  visibility: visible;
}

.menu-content {
  position: fixed;
  top: 0;
  right: -100%;
  width: 85%;
  max-width: 400px;
  height: 100%;
  background: #0438788c;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.96);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.burger-menu.open .menu-content {
  right: 0;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.menu-logo {
  width: 120px;
  height: auto;
  filter: drop-shadow(0 2px 4px rgba(255, 255, 255, 0.1));
}

.menu-close {
display: none !important;
}

.menu-close:hover {
  transform: rotate(90deg);
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0 0 auto 0;
}

.menu-item {
  margin-bottom: 1.5rem;
  opacity: 0;
  transform: translateX(20px);
  animation: menuItemAppear 0.3s ease forwards;
}

@keyframes menuItemAppear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.menu-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 12px 20px;
  border-radius: 8px;
  display: block;
  transition: all 0.2s ease;
  background: #007bff9e;
}

.menu-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(10px);
}

.status-check {
  margin: 2rem 0;
}

.status-button {
  display: block;
  background: #007bff;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  transition: background 0.2s ease;
}

.status-button:hover {
  background: #0056b3;
}

.contact-info {
  color: white;
}

.address-block {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s ease;
  border-radius: 6px;
}

.contact-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.social-links {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-link {
  font-size: 1.5rem;
  color: white;
  transition: transform 0.2s ease;
}

.social-link:hover {
  transform: translateY(-3px);
}

.vk:hover { color: #4a76a8; }
.whatsapp:hover { color: #25d366; }
.telegram:hover { color: #0088cc; }

@media (min-width: 769px) {
  .burger-menu {
    display: none;
  }
}